import React from 'react';
import styled from 'styled-components/macro';

import { ContentBlock, Headline, Player } from '../components';

import { border, mediaMobile } from '../utils/sharedStyles';

import { mixingAndMastering, productionAndComposition } from '../data';

const PlayersContainer = styled.div`
  margin: 1rem 0 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media ${mediaMobile} {
    justify-content: center;
  }
`;

const Text = styled.p`
  font: normal normal 300 20px/28px Heebo;
  margin-bottom: 3rem;
`;

const References = () => (
  <>
    <ContentBlock borderBottom={{ xs: border }} pb={8}>
      <Headline semiBold>Mixing &#38; Mastering</Headline>
      <Text>
        By clicking on the before-after switch you can hear the difference
        between the demos I get from clients and the mixed/mastered versions.
      </Text>
      <PlayersContainer>
        {mixingAndMastering.map(item => (
          <Player key={item.id} item={item} />
        ))}
      </PlayersContainer>
    </ContentBlock>
    <ContentBlock my={8} py={0}>
      <Headline semiBold>Beats &#38; Productions</Headline>
      <Text>Have a listen to my beats and productions.</Text>
      <PlayersContainer>
        {productionAndComposition.map(item => (
          <Player key={item.id} item={item} />
        ))}
      </PlayersContainer>
    </ContentBlock>
  </>
);

export default References;
